import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import * as configuration from './configuration/configuration';
import AppContainer from './containers/app-container/AppContainer';
import ResetPaswordContainer from './containers/reset-password-container/ResetPaswordContainer';
import { ToastContainer } from 'react-toastify';
import {
    createPluginStore,
    PluginProvider,
    RendererPlugin
} from "react-pluggable";
import FilteringByPickupPointPlugin from './plugins/FilteringByPickupPointPlugin';
import { Provider, useDispatch } from 'react-redux';
import store from './store/store';
import { PluginEvent } from './plugins/Extensions/pluginEvent';
import OrderEmailConfirmation from './components/order-email-confirmation/OrderEmailConfirmation';
import { getDefaultCurrency } from './shared/services/get-default-currency.service';
import { setCurrency } from './store/actions';
import SetFooterPlugin from './plugins/SetFooterPlugin';
import ReactGA from 'react-ga4';
import ShowPickupPointsPanelsPlugin from './plugins/ShowPickupPointsPanelsPlugin';
import RegistrationFormPlugin from './plugins/RegistrationFormPlugin';
import LoginFormPlugin from './plugins/LoginFormPlugin';
import HeaderNavigationPlugin from './plugins/HeaderNavigationPlugin';
import RememberMePlugin from './plugins/RememberMePlugin';
import ResetPasswordPlugin from './plugins/ResetPasswordPlugin';
import SetZziemiFooterPlugin from './plugins/SetZziemiFooterPlugin';
import SetKoszykChojnickiFooterPlugin from './plugins/SetKoszykChojnickiFooterPlugin';
import SetKoszykLisieckiFooterPlugin from './plugins/SetKoszykLisieckiFooterPlugin';
import SetSpizarnia24FooterPlugin from './plugins/SetSpizarnia24FooterPlugin';
import SliderPlugin from './plugins/SliderPlugin';
import ProductIngredientsPlugin from './plugins/ProductIngredientsPlugin';
import FixcenterProductsPagePlugin from './plugins/FixcenterProductsPagePlugin';
import SelectPickupPointPlugin from './plugins/SelectPickupPointPlugin';
import SelectSharedPickupPointPlugin from './plugins/SelectSharedPickupPointPlugin';

const TRACKING_ID = "G-MMT5QTJQ4P";
ReactGA.initialize(TRACKING_ID);
const pluginStore = createPluginStore();
pluginStore.install(new RendererPlugin());
pluginStore.install(new FilteringByPickupPointPlugin(store));

//pluginStore.install(new SetFooterPlugin);
//pluginStore.install(new SetKoszykLisieckiFooterPlugin);
//pluginStore.install(new SetZziemiFooterPlugin());
//pluginStore.install(new SetKoszykChojnickiFooterPlugin);
pluginStore.install(new SetSpizarnia24FooterPlugin());

pluginStore.install(new ShowPickupPointsPanelsPlugin(store));
pluginStore.install(new RegistrationFormPlugin());
pluginStore.install(new LoginFormPlugin());
pluginStore.install(new HeaderNavigationPlugin());
pluginStore.install(new RememberMePlugin());
pluginStore.install(new ResetPasswordPlugin());
//pluginStore.install(new RegisterNowPlugin());
pluginStore.install(new SliderPlugin());
pluginStore.install(new ProductIngredientsPlugin());
//pluginStore.install(new FixcenterProductsPagePlugin());
pluginStore.install(new SelectPickupPointPlugin());
pluginStore.install(new SelectSharedPickupPointPlugin());

function App() {

    const dispatch = useDispatch();
    const location = useLocation();
    const languageCodes: string[] = ["pl", "en", "chi", "nl", "de"];

    function getLanguageCode(): string | null {
        return localStorage.getItem("lang");
    }

    useEffect(() => {
        const currentPath = location.pathname;
        const currentLanguage = window.location.pathname.split("/")[1];

        if (currentLanguage !== getLanguageCode() && languageCodes.includes(currentLanguage)) {
            const newPath = currentPath.replace(`/${currentLanguage}`, `/${getLanguageCode()}`);
            return window.history.replaceState(null, '', newPath);
        } else if (!currentPath.startsWith(`/${getLanguageCode()}`) && !languageCodes.includes(currentLanguage)) {
            const newPath = `/${getLanguageCode()}${currentPath}`;
            return window.history.replaceState(null, '', newPath);
        }
    }, [location]);

    useEffect(() => {
        ReactGA.send('pageview');
        pluginStore.dispatchEvent(new PluginEvent("getProductsExtendedInfo"));
        getDefaultCurrency().then(response => dispatch(setCurrency(response.data)));
    }, []);

    configuration.configure();
    return (
        <PluginProvider pluginStore={pluginStore}>
            <Provider store={store}>
                <ToastContainer />
                <Routes>
                    <Route path="/*" Component={AppContainer}></Route>
                    <Route path="/:language?/reset-password/*" Component={ResetPaswordContainer}></Route>
                    <Route path="/order-email-confirmation/*" Component={OrderEmailConfirmation}></Route>
                </Routes>
            </Provider>
        </PluginProvider>
    );
}

export default App;
