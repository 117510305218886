import { useEffect, useState } from "react";
import { OrderDetailsModel } from "../../../order/models/order-details.model"
import { getCountries, getDeliveries, getPayments, getPickupPointById } from "../../../order/services";
import { Country, DeliveryMethod, PaymentMethod } from "../../../order/models";
import PickupPointPanel from "../../../../plugins/components/PickupPointPanel/PickupPointPanel";
import styles from './OrderConfirmationSummary.module.scss';
import { Typography } from "@mui/material";
import { t } from "i18next";
import { PickupPoint } from "../../../../shared/map/models";
import { getSellingSessionsPaginatedService } from "../../../selling-sessions/service";
import { SellingSession } from "../../../selling-sessions/models";
import { formatDateTimeService } from "../../../../shared/services";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";

interface OrderConfirmationSummaryProps {
    orderDetails?: OrderDetailsModel
}

export default function OrderConfrimationSummary({ orderDetails }: OrderConfirmationSummaryProps) {

    const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

    const [paymentMethodString, setPaymentMethodString] = useState<string>('');
    const [pickupPoint, setPickupPoint] = useState<PickupPoint | null>(null);
    const [sellingSessions, setSellingSessions] = useState<SellingSession[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        getCountries().then((response) => {
            if (response.data) {
                setCountries(response.data as Country[]);
            }
        });
    }, []);

    const getPaymentMethodsOnInit = (): void => {
        getPayments().then((response) => {
            if (response) {
                setPaymentMethods(response.data as PaymentMethod[]);
            }
        });
    };

    useEffect(() => {
        getPaymentMethodsOnInit();
    }, []);

    useEffect(() => {
        handlePickupPointAndSellingSession();
    }, [orderDetails]);

    useEffect(() => {
        if (paymentMethods.length > 0 && orderDetails?.order.paymentMethod) {
            const method = paymentMethods.find(x => x.id === orderDetails.order.paymentMethod);
            if (method) {
                setPaymentMethodString(method.name);
            }
        }
    }, [paymentMethods, orderDetails]);

    const calculateItemsPrice = (): number => {
        return orderDetails?.order.items.reduce((total, item) => total + ((item.netPrice + item.netPrice * item.vatRate) * item.quantity), 0) || 0;
    }

    const calculateFullPrice = (): number => {
        const deliveryPrice = orderDetails?.order.deliveryPrice ?? 0;
        return calculateItemsPrice() + Number(deliveryPrice);
    }

    const handlePickupPointAndSellingSession = () => {
        if (orderDetails?.order.pickupPointId) {
            getPickupPointById(orderDetails.order.pickupPointId).then((response) => {
                if (response) {
                    setPickupPoint(response.data as PickupPoint);
                }
            });
            getSellingSessionsPaginatedService(1, 5, orderDetails.order.pickupPointId).then((response: any) => {
                if (response) {
                    setSellingSessions(response.data.items as SellingSession[]);
                }
            });
        }
    }

    const navigateToProducts = () => {
        navigate('/');
    }

    return (
        <div className={styles.container}>
            <div className={styles.summary_header}>PODSUMOWANIE</div>
            <div className={styles.section}>

                {orderDetails?.order.pickupPointId ? (
                    <>
                        <div className={styles.section_bold_text}>Punkt odbioru</div>
                        <div>{pickupPoint?.name || 'N/A'}</div>
                        <div className={styles.section_small_text}>{pickupPoint?.address.streetName} {pickupPoint?.address.buildingNumber}</div>
                        <div className={styles.section_small_text}>{pickupPoint?.address.postalCode} {pickupPoint?.address.city}</div>
                        <div className={styles.section_bold_text}>Odbiór</div>
                        {orderDetails?.startPickupDate && orderDetails?.endPickupDate && (
                            <div>
                                od {formatDateTimeService(orderDetails.startPickupDate)}<br />
                                do {formatDateTimeService(orderDetails.endPickupDate)}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className={styles.section_bold_text}>Adres dostawy</div>
                        <div>
                            {orderDetails?.order.address.streetName + " "}
                            {orderDetails?.order.address.buildingNumber}{orderDetails?.order.address.flatNumber !== '' && `/${orderDetails?.order.address.flatNumber}`}
                        </div>
                        <div className={styles.section_small_text}>{orderDetails?.order.address.postalCode} {orderDetails?.order.address.city}</div>
                        <div className={styles.section_small_text}>{t(`countriesNames.${countries?.find(x => x.id === orderDetails?.order.address.country)?.name}`)}</div>
                    </>
                )}

            </div>
            <div className={styles.section_flex_row}>
                <div className={styles.section_bold_text}>Płatność</div>
                <div className={styles.section_bold_text}>{paymentMethodString}</div>
            </div>
            <div className={styles.section_flex_row}>
                <div className={styles.section_bold_text}>Wartość zakupów</div>
                <div className={styles.section_bold_text}>{calculateItemsPrice().toFixed(2)} PLN</div>
            </div>
            <div className={styles.section_flex_row}>
                <div className={styles.section_bold_text}>Dostawa</div>
                <div className={styles.section_bold_text}>{orderDetails?.order.deliveryPrice !== undefined ? Number(orderDetails.order.deliveryPrice).toFixed(2) : '0.00'} PLN</div>
            </div>
            <div className={styles.section_flex_row}>
                <div className={styles.section_bold_text}>Do zapłaty</div>
                <div className={styles.section_bold_text}>{calculateFullPrice().toFixed(2)} PLN</div>
            </div>
            <div className={styles.button_container}>
                <button onClick={() => { navigateToProducts() }} >POWRÓT DO ZAKUPÓW</button>
            </div>
        </div>
    );
}
